.valveButtons {
    cursor: pointer;
}

.flowing-water {
    stroke: url(#waterGradient); /* Use the new gradient */
    stroke-width: 10; /* Thicker stroke for visibility */
    stroke-linecap: round;
    animation: flow 1s linear infinite;
    /* filter: drop-shadow(0 0 8px rgba(0, 191, 255, 0.8)); Enhanced glow effect */
}

@keyframes flow {
    0% {
        stroke-dasharray: 10, 20;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 15, 20;
        stroke-dashoffset: -15;
    }
    100% {
        stroke-dasharray: 10, 20;
        stroke-dashoffset: -30;
    }
}