.pressure-valve4 {
  position: absolute;
  width: 159px;
  margin-left: 696px;
  bottom: 739px;
}
.pressure-valve3 {
    position: absolute;
    width: 89px;
    margin-left: 54px;
    bottom: 629px;
}

.pressure-valve2 {
  position: absolute;
  width: 154px;
  margin-left: 329px;
  bottom: 627px;
}

.horizontal-line1-1 {
    position: absolute;
    width: 390px; 
    height: 7px; 
    margin-top: 90px;
    margin-left: 150px;
    background-color:rgb(49, 122, 161); 
  }
 .image{
    position: absolute;
    width: 100px;
    margin-left: 530px;
    margin-top: 30px;
    transform: rotate(5deg);
 }
 .horizontal-line1-2 {
    position: absolute;
    width: 135px; 
    height: 7px; 
    margin-top: 90px;
    margin-left: 625px;
    background-color:rgb(49, 122, 161); 
  }
  .Vertical-line1-1{
    position: absolute;
    width: 100px; 
    height: 7px; 
    margin-top: 140px;
    margin-left: 104px;
    background-color:rgb(49, 122, 161); 
    transform: rotate(90deg);
  }
  .horizontal-line1-3 {
    position: absolute;
    width: 120px; 
    height: 7px; 
    margin-top: 190px;
    margin-left: 100px;
    background-color:rgb(49, 122, 161); 
  }
  .image_1{
    position: absolute;
    width: 100px;
    margin-left: 210px;
    margin-top: 130px;
    transform: rotate(5deg);
 }

  .image_2{
    position: absolute;
    width: 60px;
    margin-left: 302px;
    margin-top: 175px;
    /* transform: rotate(5deg); */
 }
  .Vertical-line1-2{
    position: absolute;
    width: 350px; 
    height: 7px; 
    margin-top: 357px;
    margin-left: 185px;
    background-color:rgb(49, 122, 161); 
    transform: rotate(90deg);
  }
    .horizontal-line1-4 {
    position: absolute;
    width: 85px; 
    height: 5px; 
    margin-top: 273px;
    margin-left: 365px;
    background-color:rgb(49, 122, 161); 
  } 
  .image_3{
    position: absolute;
    width: 65px;
    margin-left: 438px;
    margin-top: 241px;
    transform: rotate(0deg);
 }
 .horizontal-line1-5 {
    position: absolute;
    width: 85px; 
    height: 5px; 
    margin-top: 274px;
    margin-left: 490px;
    background-color:rgb(49, 122, 161); 
  }
  .horizontal-line1-6 {
    position: absolute;
    width: 85px; 
    height: 5px; 
    margin-top: 346px;
    margin-left: 365px;
    background-color:rgb(49, 122, 161); 
  } 
  .image_4{
    position: absolute;
    width: 65px;
    margin-left: 438px;
    margin-top: 314px;
    transform: rotate(0deg);
 }
 .horizontal-line1-7 {
    position: absolute;
    width: 85px; 
    height: 5px; 
    margin-top: 346px;
    margin-left: 490px;
    background-color:rgb(49, 122, 161); 
  }
  .horizontal-line1-8 {
    position: absolute;
    width: 85px; 
    height: 5px; 
    margin-top: 425px;
    margin-left: 365px;
    background-color:rgb(49, 122, 161); 
  } 
  .image_5{
    position: absolute;
    width: 65px;
    margin-left: 438px;
    margin-top: 393px;
    transform: rotate(0deg);
 }
 .horizontal-line1-9 {
    position: absolute;
    width: 85px; 
    height: 5px; 
    margin-top: 426px;
    margin-left: 491px;
    background-color:rgb(49, 122, 161); 
  }
  .horizontal-line1-10 {
    position: absolute;
    width: 85px; 
    height: 5px; 
    margin-top: 530px;
    margin-left: 363px;
    background-color:rgb(49, 122, 161); 
  } 
  .image_6{
    position: absolute;
    width: 65px;
    margin-left: 436px;
    margin-top: 497px;
    transform: rotate(0deg);
 }
 .horizontal-line1-11 {
    position: absolute;
    width: 85px; 
    height: 5px; 
    margin-top: 530px;
    margin-left: 490px;
    background-color:rgb(49, 122, 161); 
  }
  .image_7{
    position: absolute;
    width: 15px;
    margin-left: 760px;
    margin-top: 86px;
    /* transform: rotate(5deg); */
 }
 .Vertical-line1-3{
    position: absolute;
    width: 453px; 
    height: 7px; 
    margin-top: 315px;
    margin-left: 550px;
    background-color:rgb(49, 122, 161); 
    transform: rotate(90deg);
  }
  .horizontal-line1-12 {
    position: absolute;
    width: 85px; 
    height: 5px; 
    margin-top: 539px;
    margin-left: 780px;
    background-color:rgb(49, 122, 161); 
  } 
  .image_8{
    position: absolute;
    width: 65px;
    margin-left: 852px;
    margin-top: 507px;
    transform: rotate(0deg);
 }
 .horizontal-line1-13 {
    position: absolute;
    width: 85px; 
    height: 5px; 
    margin-top: 539px;
    margin-left: 904px;
    background-color:rgb(49, 122, 161); 
  }
  .horizontal-line1-14 {
    position: absolute;
    width: 85px; 
    height: 5px; 
    margin-top: 433px;
    margin-left: 780px;
    background-color:rgb(49, 122, 161); 
  } 
  .image_9{
    position: absolute;
    width: 65px;
    margin-left: 852px;
    margin-top: 401px;
    transform: rotate(0deg);
 }
 .horizontal-line1-15 {
    position: absolute;
    width: 85px; 
    height: 5px; 
    margin-top: 433px;
    margin-left: 904px;
    background-color:rgb(49, 122, 161); 
  }
  .horizontal-line1-16 {
    position: absolute;
    width: 85px; 
    height: 5px; 
    margin-top: 348px;
    margin-left: 780px;
    background-color:rgb(49, 122, 161); 
  } 
  .image_10{
    position: absolute;
    width: 65px;
    margin-left: 852px;
    margin-top: 316px;
    transform: rotate(0deg);
 }
 .horizontal-line1-17 {
    position: absolute;
    width: 85px; 
    height: 5px; 
    margin-top: 348px;
    margin-left: 904px;
    background-color:rgb(49, 122, 161); 
  }
  .horizontal-line1-18 {
    position: absolute;
    width: 85px; 
    height: 5px; 
    margin-top: 277px;
    margin-left: 780px;
    background-color:rgb(49, 122, 161); 
  } 
  .image_11{
    position: absolute;
    width: 65px;
    margin-left: 852px;
    margin-top: 244px;
    transform: rotate(0deg);
 }
 .horizontal-line1-19 {
    position: absolute;
    width: 85px; 
    height: 5px; 
    margin-top: 277px;
    margin-left: 904px;
    background-color:rgb(49, 122, 161); 
  }
  .image_12{
    position: absolute;
    width: 10px;
    margin-left: 575px;
    margin-top: 272px;
    /* transform: rotate(5deg); */
 }
 .image_13{
    position: absolute;
    width: 10px;
    margin-left: 575px;
    margin-top: 344px;
    /* transform: rotate(5deg); */
 }
 .image_14{
    position: absolute;
    width: 10px;
    margin-left: 575px;
    margin-top: 424px;
    /* transform: rotate(5deg); */
 }
 .image_15{
    position: absolute;
    width: 10px;
    margin-left: 575px;
    margin-top: 528px;
    /* transform: rotate(5deg); */
 }
 .image_16{
    position: absolute;
    width: 10px;
    margin-left: 989px;
    margin-top: 536px;
    /* transform: rotate(5deg); */
 }
 .image_17{
    position: absolute;
    width: 10px;
    margin-left: 989px;
    margin-top: 431px;
    /* transform: rotate(5deg); */
 }
 .image_18{
    position: absolute;
    width: 10px;
    margin-left: 989px;
    margin-top: 346px;
    /* transform: rotate(5deg); */
 }
 .image_19{
    position: absolute;
    width: 10px;
    margin-left: 989px;
    margin-top: 275px;
    /* transform: rotate(5deg); */
 }
 .image_20{
  position: absolute;
  width: 300px;
  margin-left: 1110px;
  bottom: 470px;
  /* transform: rotate(5deg); */
}
.image_21{
  position: absolute;
  width: 50px;
  margin-left: 305px;
  bottom: 404px;
  transform: rotate(270deg);
}
.image_22{
  position: absolute;
  width: 50px;
  margin-left: 719px;
  bottom: 400px;
  transform: rotate(270deg);
}
.image_23{
  position: absolute;
  width: 20px;
  margin-left: 130px;
  bottom: 623px;
  /* transform: rotate(5deg); */
}
.image_24{
  position: absolute;
  width: 20px;
  margin-left: 240px;
  bottom: 649px;
  /* transform: rotate(5deg); */
}

.image_25{
  position: absolute;
  width: 180px;
  margin-left: 1200px;
  bottom: 520px;
  /* transform: rotate(5deg); */
}

.text_1{
  position: absolute;
  margin-left: 220px;
  margin-top: 100px;
  font-size: 20px;
  color: green;
}
.text_2{
  position: absolute;
  margin-left: 320px;
  margin-top: 100px;
  font-size: 20px;
  color: red;
}
.text_3{
  position: absolute;
  margin-left: 100px;
  margin-top: 220px;
  font-size: 17px;
}
.text_4{
  position: absolute;
  margin-left: 100px;
  margin-top: 250px;
  font-size: 17px;
}
.text_5{
  position: absolute;
  margin-left: 182px;
  margin-top: 350px;
  font-size: 20px;
}
.text_6{
  position: absolute;
  margin-left: 250px;
  margin-top: 370px;
  font-size: 20px;
}
.text_7{
  position: absolute;
  margin-left: 100px;
  margin-top: 450px;
  font-size: 22px;
}
.text_8{
  position: absolute;
  margin-left: 440px;
  margin-top: 223px;
  font-size: 20px;
}
.text_9{
  position: absolute;
  margin-left: 440px;
  margin-top: 298px;
  font-size: 20px;
}
.text_10{
  position: absolute;
  margin-left: 440px;
  margin-top: 376px;
  font-size: 20px;
}
.text_11{
  position: absolute;
  margin-left: 440px;
  margin-top: 480px;
  font-size: 20px;
}
.text_12{
  position: absolute;
  margin-left: 852px;
  margin-top: 225px;
  font-size: 20px;
}
.text_13{
  position: absolute;
  margin-left: 852px;
  margin-top: 301px;
  font-size: 20px;
}
.text_14{
  position: absolute;
  margin-left: 852px;
  margin-top: 385px;
  font-size: 20px;
}
.text_15{
  position: absolute;
  margin-left: 852px;
  margin-top: 489px;
  font-size: 20px;
}
.text_16{
  position: absolute;
  margin-left: 595px;
  margin-top: 339px;
  font-size: 20px;
}
.text_17{
  position: absolute;
  margin-left: 675px;
  margin-top: 358px;
  font-size: 20px;
}
.text_18{
  position: absolute;
  margin-left: 555px;
  margin-top: 100px;
  font-size: 20px;
  color: green;
}
.text_19{
  position: absolute;
  margin-left: 555px;
  margin-top: 120px;
  font-size: 20px;
  color: red;
}
.text_20{
  position: absolute;
  margin-left: 557px;
  margin-top: 160px;
  font-size: 17px;
}
.text_21{
  position: absolute;
  margin-left: 1050px;
  margin-top: 50px;
  font-size: 22px;
}
.text_22{
  position: absolute;
  margin-left: 1000px;
  margin-top: 150px;
  font-size: 20px;
}